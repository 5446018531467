<template>
  <div>
    <section class="section" style="padding-top: 0px;">
      <div class="container Nunito" style="padding: 0;">
        <section class="section headerSection" style="padding-top: 20px;">
          <div class="container has-text-centered">
            <h1 class="title is-2 noselect">Commands!</h1>
            <h1 style="margin-bottom:0px" class="subtitle has-text-white is-5 noselect">Here is all of Grace's commands.</h1>
            <h1 style="margin-bottom:0px" class="subtitle has-text-white is-6 noselect">Some commands may not show up depending on where your viewing site!</h1>
          </div>
        </section>
        <section class="section" style="padding: 0;">
          <div class="columns">
            <div class="column is-centered" style="padding: 0px;">
              <div class="card options has-shadow has-shadow has-text-white">
                <h1 class="title">Categories:</h1>
                <h2 class="subtitle has-text-grey is-6">
                  The categories of our commands.
                  <br>Any command can be ran with <code style="background-color: var(--main-color-bg-2);">/</code> or your prefix (default <code style="background-color: var(--main-color-bg-2);">!</code>)
                  <br><code style="background-color: var(--main-color-bg-2);">G-*</code> = Grace Staff Command.
                  <br><code>Required/Requested Permissions</code> = May require permissions depending the arguments provided to the command.
                  <br><code>[]</code> = Required Argument
                  <br><code>()</code> = Optional Argument
                </h2>
                <b-field class="hovermessagefeild" label="Command Search">
                  <b-input custom-class="input-default" maxlength="40" has-counter v-model="cmdSearch" placeholder="Enter command" :icon-right="cmdSearch ? 'close-circle' : null" icon-right-clickable @icon-right-click="cmdSearch = null"></b-input>
                </b-field>
                <b-menu>
                  <b-menu-list label="">
                    <b-menu-item v-for="(category) in categorys" :key="category" :value="category" @click="selectedCategory = category; cmdSearch = null;" :active="selectedCategory === category">
                      <template slot="label">
                        <span>
                          {{category.charAt(0).toUpperCase() + category.slice(1)}}
                        </span>
                      </template>
                    </b-menu-item>
                  </b-menu-list>
                </b-menu>
                <h2 v-if="shard !== 0" class="subtitle has-text-grey is-6" style="margin-top: 10px;">
                  Searching on shard <b>{{shard}}</b>
                </h2>
              </div>
              <div class="card options has-shadow has-shadow has-text-white">
                <h1 class="title">Extra Settings:</h1>
                <p class="subtitle has-text-grey is-6">These are extra options simply for the UI.</p>
                <div class="columns">
                  <div class="column">
                    <b-checkbox :disabled="this.$store.getters.admin.topggRestrictions" v-model="filterNSFW" type="is-info">Filter NSFW Commands</b-checkbox>
                    <p v-if="this.$store.getters.admin.topggRestrictions" class="subtitle has-text-grey is-7" style="margin-bottom: 10px !important;">Disabled due to Top.GG TOS.</p>
                    <!-- <br> -->
                    <b-checkbox v-model="showBeta" type="is-info">Show Beta Commands</b-checkbox>
                    <!-- <br> -->
                    <b-checkbox v-model="showPremium" type="is-info">Show Premium Commands</b-checkbox>
                    <br>
                    <b-field label="Filter by System Staff Type">
                      <b-select v-model="staffTypeSel">
                        <option v-for="type in this.staffTypeList" :value="type.id" :key="type.id">
                          {{ type.name }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                </div>
              </div>
              <div class="card options has-shadow has-shadow has-text-white">
                <h1 class="title">Did you know?</h1>
                <p>Purchasing premium for your server, you unblock more xp settings like min/max xp, x2 xp multiplier per message and more!</p>
                <p>Or by voting on TOP.GG you get x1.5 xp per message!</p>
                <div class="buttons" style="padding-top: 10px;">
                  <b-button type="is-success" tag="router-link" to="/premium">Upgrade to premium</b-button>
                  <b-button type="is-info" tag="router-link" to="/vote?ref=commands">Vote via TOP.GG</b-button>
                </div>
              </div>
            </div>
            <div class="column is-centered has-shadow is-two-thirds list">
              <h1 v-if="!selectedCommands || (selectedCommands || []).length <= 0" class="title is-2 noselect has-text-centered">No commands found!</h1>
              <b-collapse class="card command command-column" animation="slide" v-for="(command, index) of selectedCommands" :key="index" @close="isOpen = false" :open="isOpen === false ? false : (isOpen === command.name || isOpen === 'all')" @open="isOpen === 'all' ? isOpen = 'all' : isOpen = command.name">
                <div slot="trigger" slot-scope="props" class="card-header command-header">
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'caret-up' : 'caret-down'"> </b-icon>
                    <!-- <button class="delete command-delete" @click.stop="deletecommand($event, props, command.id, command)"></button> -->
                  </a>
                  <p class="card-header-title command-header-title">
                    <span style="padding-right: 10px; margin-bottom:0px;" class="title is-5 has-text-white Nunito"><code style="background-color: var(--main-color-bg-2);">{{command.name}}</code></span>
                    <span v-if="!props.open" style="padding-left: 10px;margin-left: -10px;margin-bottom:-25px;font-family: var(--font-main);" class="subtitle is-6 has-text-white noselect Nunito">{{command.discord.description}}</span>
                  </p>
                  <a class="card-header-icon">
                    <b-taglist style="float: right;">
                      <b-tag v-if="command.staffType.id !== 0" id="command-status-title-tag" ellipsis type="is-danger">G-{{command.staffType.name}}</b-tag>
                      <b-tag v-if="command.premium" id="command-status-title-tag" ellipsis type="is-warning">PREMIUM</b-tag>
                      <b-tag v-if="command.nsfw" id="command-status-title-tag" ellipsis type="is-danger">NSFW</b-tag>
                      <b-tag v-if="command.beta" id="command-status-title-tag" ellipsis type="is-warning">BETA</b-tag>
                    </b-taglist>
                  </a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <p class="has-text-grey-light">{{command.discord.description}}</p>
                    <div class="columns">
                      <div class="column" v-if="command.permReq.length > 0">
                        <label class="label has-text-white">Required/Requested Permissions</label>
                        <p class="has-text-grey-light is-6"><code style="background-color: var(--main-color-bg-2);">{{command.permReq.map(g => g.toLowerCase().split('_').map(f => f.charAt(0).toUpperCase() + f.slice(1)).join(" ")).join(", ")}}</code></p>
                      </div>
                      <div class="column" v-if="command.howtouse.length > 0">
                        <label class="label has-text-white">How to use</label>
                        <p class="has-text-grey-light is-6"><code style="background-color: var(--main-color-bg-2);">{{(command.howtouse || "").toString().replaceAll("(prefix)", "")}}</code></p>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column" v-if="command.examples.length > 0">
                        <label class="label has-text-white">Examples</label>
                          <p class="has-text-grey-light is-6" v-for="example, index in command.examples" :key="example" style="margin-bottom: unset !important;" :class="{'margintop5': index !== 0}">
                            <code style="background-color: var(--main-color-bg-2);">{{example.toString().replaceAll("(prefix)", "!")}}</code>
                          </p>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>
<script>
  export default {
    name: 'commands',
    data () {
      return {
        commands: [],
        shard: 0,
        selectedCategory: 'all',
        filterNSFW: false,
        isOpen: false,
        cmdSearch: null,
        showBeta: true,
        showPremium: true,
        staffTypeSel: 0,
        staffTypeList: [
          {
            id: 0,
            name: 'Any'
          },
          {
            id: 1,
            name: 'Partner'
          },
          {
            id: 2,
            name: 'Helpful/Trusted'
          },
          {
            id: 3,
            name: 'Helper'
          },
          {
            id: 4,
            name: 'Mod'
          },
          {
            id: 5,
            name: 'Admin'
          },
          {
            id: 6,
            name: 'Sr Admin'
          },
          {
            id: 7,
            name: 'Dev'
          },
          {
            id: 8,
            name: 'Management'
          },
          {
            id: 9,
            name: 'Owner'
          },
          {
            id: 10,
            name: 'System'
          }
        ]
      }
    },
    components: {},
    async created () {
      if (this.$route.query.shard && !isNaN(this.$route.query.shard)) this.shard = Number(this.$route.query.shard) || 0
      if (this.$route.query.search) this.cmdSearch = this.$route.query.search
      if (this.$route.query.category && !this.$route.query.search) this.selectedCategory = this.$route.query.category
      if (this.$store.getters.admin.topggRestrictions) this.filterNSFW = true
      return this.loadCommands()
    },
    watch: {
      'categorys' () {
        if (!this.categorys.includes(this.selectedCategory)) this.selectedCategory = 'all'
      },
      'cmdSearch' () {
        this.isOpen = false
        if (!this.cmdSearch) return
        this.selectedCategory = 'all'
      },
      'selectedCommands' () {
        if (this.selectedCommands.length > 1 && this.selectedCommands[0]?.name === this.cmdSearch) this.isOpen = this.selectedCommands[0].name
        else if (this.selectedCommands.length === 1) this.isOpen = this.selectedCommands[0].name
      },
      '$store.getters.admin.topggRestrictions' () {
        this.filterNSFW = this.$store.getters.admin.topggRestrictions
      }
    },
    computed: {
      categorys () {
        const categories = ['all', ...new Set(this.commands?.map(g => g.category) || [])]
        return categories
      },
      selectedCommands () {
        let cmd = this.commands
        if (this.filterNSFW) cmd = cmd.filter(g => !g.nsfw)
        if (!this.showBeta) cmd = cmd.filter(g => !g.beta)
        if (!this.showPremium) cmd = cmd.filter(g => !g.premium)
        if (this.staffTypeSel) cmd = cmd.filter(g => g.staffType <= this.staffTypeSel)
        if (this.cmdSearch) return cmd.filter(g => g.name.includes(this.cmdSearch))
        if (this.selectedCategory === 'all') return cmd
        return cmd.filter(g => g.category === (this.selectedCategory || this.categorys[0]))
      }
    },
    methods: {
      loadCommands () {
        return this.$store.dispatch('getApi', { path: 'plugin/shards/commands', params: { shard: this.shard } }).then(async (result) => {
          this.commands = result.data.commands.map(g => { return { ...g, staffType: this.staffType(g.staffType) } })
        }).catch((error) => {
          console.log(error, 'error')
          return error
        })
      },
      staffType (value) {
        return this.staffTypeList.find(g => g.id === value) || null
      }
    }
  }
</script>

<style lang="scss" scoped>
  @media only screen and (max-width: 768px) {
    #command-status-title-tag-2 {
      display: inherit !important;
    }
    .command {
      box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
    }
    .card-header-icon:last-child {
      display: none;
    }
    .hovermessagefeild > .help {
      display: unset !important;
    }
  }

  .margintop5 {
    margin-top: 5px;
  }

  .command-column {
    margin-bottom: 10px;
    padding: 5px;
    padding-bottom: 0px;
  }

  .command {
    border-radius: 1px !important;
    border-color: transparent !important;
    border-width: 1px !important;
    border-radius: 10px !important;
    border-style: solid !important;
    background: var(--main-color-bg-1) !important;
    color: #fff !important;
  }
  .modal-close:hover::before, .delete:hover::before, .modal-close:hover::after, .delete:hover::after {
    background-color: #f04747 !important;
  }

  .command-header-title {
    color: #fff !important;
  }

  .has-shadow {
    box-shadow: 0 0.1rem 1.5rem rgba(0,0,0,.3) !important;
  }
  .radio:hover, .checkbox:hover {
    color: unset !important;
  }
  @media screen and (min-width: 768px) {
    .list {
      margin-left: 40px;
      margin-top: 0px !important;
    }
    .TOTAL-2 {
      margin-left: 20px;
      margin-top: 0px !important;
    }
    #level {
      width: 50%;
    }
  }
  .options {
    background: var(--main-color-bg-3) !important;
    border-radius: 10px !important;
    min-height: 200px !important;
    height: fit-content !important;
    padding: 10px;
  }
  .options:not(:first-child) {
    margin-top: 30px;
  }
  .list {
    background: var(--main-color-bg-3);
    border-radius: 10px;
    min-height: 64px;
    margin-top: 10px;
    height: fit-content;
  }
</style>

<style>
  .menu-list li ul {
    border-left: 1px solid var(--main-color-bg-3) !important;
  }
  .menu-label {
    color: lightgrey;
  }
  .menu-list a {
    color: white !important;
  }
  .menu-list a.is-active:hover {
    background-color: var(--main-color-bg-2) !important;
  }
  .menu-list a:hover {
    background-color: var(--main-color-bg-1) !important;
    color: white !important;
  }
  .menu-list a.is-active {
    background-color: #7957d5 !important;
  }
  .card {
    background-color: var(--main-color-bg) !important;
    /* height: 200px; */
    border-color: #6c757d!important;
    border: 1px solid rgba(0,0,0,.125) !important;
    border-radius: .25rem !important;
    /* cursor: pointer; */
    /* transition: .3s; */
  }
</style>
